import { FaUserSecret, FaWalking } from 'react-icons/fa';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { IoIosRocket } from 'react-icons/io';
import { Link } from '@reach/router';
import { RiRefreshLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import Hero from '../components/landing/Hero';
// import Screenshots from '../components/landing/Screenshots';
import Wrapper from '../components/shared/Wrapper';
import ModalContext from '../contexts/ModalContext';
import UserContext from '../contexts/UserContext';

const Home = () => {
  const { t } = useTranslation();

  const { emitter, events } = useContext( ModalContext );
  const { user, loading } = useContext( UserContext );

  const handleLogin = () => navigate( '/login' );//emitter.emit( events.AUTH_MODAL );

  const handleGotoApp = () => navigate( '/app/dashboard' );

  return (
    <Wrapper>
      <Helmet>
        <link rel="canonical" href="https://document.boardsi.com/" />
      </Helmet>

      <div className="w-full bg-white fixed z-50 border-b-2 border-blue-gray-200">
    
    <div className="relative z-20">
        <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
            <div>
                <a href="#" className="flex">
                    <img className="h-8 w-auto sm:h-10 "
                        src="https://boardsi.com/wp-content/uploads/2021/03/boardsi-logo-flat-1200-1024x229.png" alt=""/>
                </a>
            </div>
            <div className="flex-1 flex items-center justify-between">
                <nav className="flex space-x-10"/>
                <div className="flex items-center md:ml-12">
                {user ? (
                  <div onClick={handleGotoApp} className="box-border no-underline relative z-30 inline-flex items-center justify-center w-auto px-6 py-2 overflow-hidden font-bold text-white transition-all duration-300 bg-indigo-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-indigo-400 ease focus:outline-none">
                    <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:-translate-x-1"/>
                    <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-1"/>
                    <span className="relative z-20 flex items-center text-sm no-underline">
                      <svg className="relative w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/></svg>
                        Board Documents
                    </span>
                  </div>
                ) : (
                    <div onClick={handleLogin} className="box-border no-underline relative z-30 inline-flex items-center justify-center w-auto px-6 py-2 overflow-hidden font-bold text-white transition-all duration-300 bg-emerald-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-emerald-300 ring-offset-emerald-200 hover:ring-offset-emerald-400 ease focus:outline-none">
                    <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:-translate-x-1"/>
                    <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-1"/>
                      <span className="relative z-20 flex items-center text-sm no-underline">
                        <svg className="relative w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/></svg>
                        Sign In
                    </span>
                  </div>
                )}
                </div>
            </div>
        </div>
    </div>

</div>

<div className="bg-white">
    <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
                <div className="mt-16 mb-16">
                    <div className="mt-6 sm:max-w-xl">
                        <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Board Documents for
                            growing executives</h1>
                        <p className="mt-6 text-xl text-gray-500">Resumes and CVs are a thing of the past. Show proven
                            leadership and success with a single document.</p>
                    </div>
                
                    <div className="mt-6">
                        <div className="inline-flex items-center divide-x divide-gray-300">
                            <div className="flex-shrink-0 flex pr-5">
                                
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                                
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                                
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                                
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                                
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                            </div>
                            <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3"><span
                                    className="font-medium text-gray-900">Rated 5 stars</span> by over <span
                                    className="font-medium text-indigo-600">500 Executives</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <div className="hidden sm:block">
                    <div className="absolute inset-y-0 left-1/2 w-screen bg-white rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                    <svg className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0" width="404" height="392" fill="none"
                        viewBox="0 0 404 392">
                        <defs>
                            <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                                patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                    </svg>
                </div>
                <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                              <img className="w-full rounded-md shadow-xl slide-in-right lg:h-full lg:w-auto lg:max-w-7xl"
                                  src="https://document.boardsi.com/images/screenshots/screen-2.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>


      <div className="bg-white">
        <div className="relative sm:py-16">
          <div aria-hidden="true" className="hidden sm:block">
            <div className="absolute inset-y-0 left-0 w-full rounded-r-3xl" />
          </div>
          <div className="">
            <div className="relative px-6 py-10 bg-indigo-600 overflow-hidden shadow-sm sm:px-12 sm:py-20">
              <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                  <path className="text-indigo-500 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
                  <path className="text-indigo-700 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
                </svg>
              </div>
              <div className="relative max-w-md sm:max-w-3xl lg:max-w-7xl mx-auto">
                              <h2 className="text-3xl font-extrabold text-white">The world&#39;s most innovative companies use <span className="underline underline-offset-8">Boardsi Documents</span> to find their new Board Advisors or Board Director members.</h2>
                <div className="flow-root mt-8 lg:mt-10">
                  <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
                    <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
                      <img className="h-12" src="https://tailwindui.com/img/logos/tuple-logo-indigo-300.svg" alt="Tuple" />
                    </div>
                    <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
                      <img className="h-12" src="https://tailwindui.com/img/logos/mirage-logo-indigo-300.svg" alt="Mirage" />
                    </div>
                    <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
                      <img className="h-12" src="https://tailwindui.com/img/logos/statickit-logo-indigo-300.svg"
                        alt="StaticKit" />
                    </div>
                    <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
                      <img className="h-12" src="https://tailwindui.com/img/logos/transistor-logo-indigo-300.svg"
                        alt="Transistor" />
                    </div>
                    <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
                      <img className="h-12" src="https://tailwindui.com/img/logos/workcation-logo-indigo-300.svg"
                        alt="Workcation" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


<div className="relative bg-white pt-16 pb-8 overflow-hidden">
    <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                    <div>
                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                            
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>
                        </span>
                    </div>
                    <div className="mt-6">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Keep your document future ready
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">In a fast paced world, its not easy to keep everything up
                            to date. With boardsi Board Document, you dont have to worry about being caught off guard.
                            Always be ready to stand out and above the crowd when applying for Board Positions.</p>
                        <div className="mt-6">
                            <a href="#"
                                className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                                Get started </a>
                        </div>
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                    <blockquote>
                        <div>
                            <p className="text-base text-gray-500">&ldquo;CVs and resumes dont convey correctly the needs of the board. Thanks to Boardsi Boardi Documents, we were able to choose the right qualified member for our Board.&rdquo;</p>
                        </div>
                        <footer className="mt-3">
                            <div className="flex items-center space-x-3">
                                <div className="flex-shrink-0">
                                    <img className="h-6 w-6 rounded-full"
                                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                        alt="" />
                                </div>
                                <div className="text-base font-medium text-gray-700">Marcia Hill, Chairman, Board of Directors
                                </div>
                            </div>
                        </footer>
                    </blockquote>
                </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                  src="https://document.boardsi.com/images/screenshots/screen-4.png"
                        alt="Inbox user interface" />
                </div>
            </div>
        </div>
    </div>
    <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                    <div>
                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                            
                            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                            </svg>
                        </span>
                    </div>
                    <div className="mt-6">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Share your vision with the
                            world</h2>
                        <p className="mt-4 text-lg text-gray-500">With boardsi Board Documents, you can download all of your
                            data directly from our tool. We also offer single and multi page formatting, physical print
                            options, direct to pdf, as well as an always ready online hosted Board Document. Experience
                            freedom with no vendor lock in.</p>
                        <div className="mt-6">
                            <a href="#"
                                className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                                Get started </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                                  src="https://document.boardsi.com/images/screenshots/screen-3.png"
                        alt="Customer profile user interface" />
                </div>
            </div>
        </div>
    </div>
</div>
<footer className="bg-white">
    <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
            <div className="px-5 py-2">
                <a href="https://boardsi.com" className="text-base text-gray-500 hover:text-gray-900">
                    About
                </a>
            </div>

            <div className="px-5 py-2">
                <a href="#" className="text-base text-gray-500 hover:text-gray-900">
                    Blog
                </a>
            </div>

            <div className="px-5 py-2">
                <a href="#" className="text-base text-gray-500 hover:text-gray-900">
                    Press
                </a>
            </div>

            <div className="px-5 py-2">
                <a href="https://boardsi.com/tos.html" className="text-base text-gray-500 hover:text-gray-900">
                    Terms of Service
                </a>
            </div>
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
            <a href="#" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">Facebook</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd" />
                </svg>
            </a>

            <a href="#" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">Instagram</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd" />
                </svg>
            </a>

            <a href="#" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">Twitter</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path
                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
            </a>
        </div>
        <p className="mt-8 text-center text-base text-gray-400">
            &copy; 2022 Boardsi, Inc. All rights reserved.
        </p>
    </div>
</footer>
 

    </Wrapper>
  );
};

/*
const Feature = ({ icon: Icon, title, children }) => (
  <div className="mt-16">
    <div className="flex items-center">
      <Icon size="18px" className="text-primary-900 mr-4" />
      <div className="text-3xl">{title}</div>
    </div>
    <p className="mt-6 text-lg leading-loose">{children}</p>
  </div>
);
*/

export default memo(Home);

export async function getServerData() {}